import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSubway, faWalking } from '@fortawesome/free-solid-svg-icons';

import Address from './Address';

const Iframe = styled.iframe`
  border: inherit;
  margin: 10px 0;
`;

// const OpenGoogleMapAppButton = () => {}; // TODO

const SubTitle = styled.span`
  font-size: 24px;
  color: #333333;
`;

const Text = styled.span`
  font-size: 16px;
`;
const Common = styled(Text)`
  color: #333333;
`;
const Yamanote = styled(Text)`
  color: #99cc00;
`;
const Saikyo = styled(Text)`
  color: #00ac9a;
`;
const Ginza = styled(Text)`
  color: #f39700;
`;
const Hanzomon = styled(Text)`
  color: #8f76d6;
`;
const Hukutoshin = styled(Text)`
  color: #9c5e31;
`;
const Tokyu = styled(Text)`
  color: #20a288;
`;

const TrainBox = ({ trainLines, exit }) => (
  <Flex
    center
    css={`
      width: 322px;
      border-radius: 6px;
      border: solid 1px #707070;
      background-color: #ffffff;
      margin-top: 20px;
      margin-bottom: 20px;
    `}
  >
    <FlexItem
      css={`
        margin-top: 20px;
        margin-bottom: 20px;
      `}
    >
      <Flex column center>
        <FlexItem>
          <Flex>
            <FontAwesomeIcon
              icon={faSubway}
              style={{ fontSize: 23, marginRight: 10 }}
              color="#000"
            />
            {trainLines}
          </Flex>
        </FlexItem>
        <FlexItem>
          <Flex>
            <FontAwesomeIcon
              icon={faWalking}
              style={{ fontSize: 23, marginRight: 10 }}
              color="#000"
            />
            {exit}
          </Flex>
        </FlexItem>
      </Flex>
    </FlexItem>
  </Flex>
);

const langs = {
  title: { ja: '渋谷駅より徒歩2分', en: '2 minutes walk from SHIBUYA station' },
  yamanote: { ja: '山手線', en: ' Yamanote Line' },
  saikyou: { ja: 'JR埼京線', en: 'JR Saikyo Line' },
  metro: { ja: '東京メトロ', en: 'Tokyo Metro' },
  ginza: { ja: '銀座線', en: ' Ginza Line' },
  miyasaka: { ja: '宮益坂方面出口', en: 'Miyamasuzaka Exit' },
  hanzo: { ja: '半蔵門線', en: ' Hanzomon Line' },
  fuku: { ja: '副都心線', en: 'Fukutoshin Line' },
  denen: { ja: '東急田園都市線', en: 'Den-en-toshi Line' },
  b2: { ja: 'B2出口', en: 'B2 Exit' },
};

const AccessInfoArea = ({ size, lang = 'ja' }) => (
  <>
    <Iframe
      css
      width="100%"
      height="360px"
      src="https://www.google.co.jp/maps?f=q&amp;source=embed&amp;hl=ja&amp;geocode=&amp;q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B71-24-6&amp;sll=36.5626,136.362305&amp;sspn=47.929672,79.013672&amp;brcurrent=3,0x60188ca7708bff2d:0xd910777563a5f6d9,1&amp;ie=UTF8&amp;hq=&amp;hnear=%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%8B%E8%B0%B7%E5%8C%BA%E6%B8%8B%E8%B0%B7%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%92%EF%BC%94%E2%88%92%EF%BC%96&amp;ll=35.660365,139.701633&amp;spn=0.020921,0.021372&amp;z=14&amp;iwloc=A&amp;output=embed"
    />
    {/* <ToGoGoogleMapButton /> */}
    <Flex
      center
      css={`
        margin: 10px 0;
      `}
    >
      <SubTitle>{langs.title[lang]}</SubTitle>
    </Flex>
    <Flex wrap="true" justifyBetween>
      <TrainBox
        trainLines={
          <div>
            {lang === 'ja' ? (
              <>
                <div>
                  <Common>JR</Common>
                  <Yamanote>{langs.yamanote[lang]}</Yamanote>
                  <Common>・</Common>
                  <Saikyo>{langs.saikyou[lang]}</Saikyo>
                  <Common>・</Common>
                </div>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Ginza>{langs.ginza[lang]}</Ginza>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Common>JR</Common>
                  <Yamanote>{langs.yamanote[lang]}</Yamanote>
                </div>
                <div>
                  <Saikyo>{langs.saikyou[lang]}</Saikyo>
                </div>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Ginza>{langs.ginza[lang]}</Ginza>
                </div>
              </>
            )}
          </div>
        }
        exit={<div>{langs.miyasaka[lang]}</div>}
      />
      <TrainBox
        trainLines={
          <div>
            {lang === 'ja' ? (
              <>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Hanzomon>{langs.hanzo[lang]}</Hanzomon>
                  <Common>・</Common>
                  <Hukutoshin>{langs.fuku[lang]}</Hukutoshin>
                  <Common>・</Common>
                </div>
                <div>
                  <Tokyu>{langs.denen[lang]}</Tokyu>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Hanzomon>{langs.hanzo[lang]}</Hanzomon>
                </div>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Hukutoshin>{langs.fuku[lang]}</Hukutoshin>
                </div>
                <div>
                  <Common>{langs.metro[lang]}</Common>
                  <Tokyu>{langs.denen[lang]}</Tokyu>
                </div>
              </>
            )}
          </div>
        }
        exit={<div>{langs.b2[lang]}</div>}
      />
      {size !== 'sm' && (
        <Flex
          center
          css={`
            margin-top: 20px;
            margin-bottom: 20px;
          `}
        >
          <Address lang={lang} />
        </Flex>
      )}
    </Flex>
  </>
);

export default AccessInfoArea;
